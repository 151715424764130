import * as React from 'react';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import GitHubIcon from '@mui/icons-material/GitHub';
import FolderIcon from '@mui/icons-material/Folder';


type GithubLocalModeToggleProps = {
    mode: "github" | "local",
    setMode: (mode:"github" | "local")=>void
}

const GithubLocalModeToggle = (props: GithubLocalModeToggleProps)=> {
  const  {mode, setMode} = props

  const handleMode = (
    event: React.MouseEvent<HTMLElement>,
    newMode:  "github" | "local",
  ) => {
    setMode(newMode);
  };

  return (
    <ToggleButtonGroup
      value={mode}
      exclusive
      onChange={handleMode}
      aria-label="explorer mode"
      size="small"
    >
      <ToggleButton value="local" aria-label="left aligned" color='info'>
        <FolderIcon />
      </ToggleButton>
      <ToggleButton value="github" aria-label="right aligned" color='info'>
        <GitHubIcon />
      </ToggleButton>
    
    </ToggleButtonGroup>
  );
}


export {GithubLocalModeToggle }