import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material'
type CompoundTabProps = {
    title:string,
    subheader?: string,
    onClose:()=>void,
    selected:boolean,
    onSelect:()=>void
}

const CompoundTab = (props:CompoundTabProps) => {
 

  const {title, subheader, onClose, selected, onSelect} = props
  const theme = useTheme()
  return (
    <Card 
       
        sx={{ 
        // flex: 1,
        // flexGrow:0,
        // flexBasis: "20%",
         background: selected?theme.palette.info.light:theme.palette.common.white,
         maxHeight: `${theme.spacing(8)}`,
         //height: `${theme.spacing(8)}`,
         minWidth:150,
         width:250,
         maxWidth:250,
        
         borderBottomLeftRadius:0,
         borderBottomRightRadius:0,
         p:0,
         m:0,
     
         
    }}>
      <CardHeader
       selected={selected}
      sx={{
        p:0,
        m:0,
    //flexDirection:"row-reverse"
        
       
      }}
       onClick={(e)=>{
        
        console.log("CardHeader.onClick", title)
        onSelect()
       }}
        avatar={
          <Avatar sx={{ bgcolor: "transparent",width: 24, height: 24,m:0, p:0,  ml:1 , mr:-1}} aria-label="recipe"  variant="square">
          <IconButton aria-label="settings"
        
        
         onClick={(e)=>{
          // e.preventDefault()
           e.stopPropagation()
           console.log("CardHeader.IconButton.Close", title)
           onClose()
          }}
         >
           <CloseIcon />
         </IconButton>

          </Avatar>
        }
        
        title={title}
        subheader={subheader ?? null}
        titleTypographyProps={{
            sx: {
                whiteSpace:"nowrap",
                width:"100%",
                maxWidth:"100%",
                textOverflow:"ellipsis",
                overflow:"hidden",
                cursor:"pointer",
         
             
             
            }
        }}

        subheaderTypographyProps={{
            sx: {
                whiteSpace:"nowrap",
                width:"100%",
                maxWidth:"100%",
                textOverflow:"ellipsis",
                overflow:"hidden",
                cursor:"pointer"
             
            }
        }}
      />


  
    </Card>
  );
}


export {CompoundTab}