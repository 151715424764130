
import { useState, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { Box } from "@mui/material"
import { MdEditorMachineContext } from "../machines/main";
import { CompoundTab } from './CompoundTab'
import Stack from '@mui/material/Stack';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import { ActiveTabContent } from './ActiveTabContent'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import MenuIcon from '@mui/icons-material/Menu';

import {NoOpenTabsView} from './NoOpenTabsView'
const makeLabel = (path: string) => {
    const filename = path.split("/").pop()
    return filename
}

async function getFile() {
    // Open file picker and destructure the result the first handle
    //@ts-ignore
    const [fileHandle] = await window.showOpenFilePicker();

    const file = await fileHandle.getFile();
    return file;
  }

const EditorSample = () => {
    const theme = useTheme()

    const files = MdEditorMachineContext.useSelector((state) => state.context.files);
    const active_tab = MdEditorMachineContext.useSelector((state) => state.context.active_tab);
    const ui_settings = MdEditorMachineContext.useSelector((state) => state.context.ui_settings);
    const svc = MdEditorMachineContext.useActorRef();

 

    // useEffect(() => {
    //     const subscription = svc.subscribe((snapshot) => {
    //         // simple logging
    //         console.log("EditorSample.subscription state change", snapshot);
    //     });

    //     return subscription.unsubscribe;
    // }, []); // note: actor ref should never change

    // useEffect(() => {
    //     try {

    //         const child_svc = files.find(x => x.id === active_tab)
    //         console.log("EditorSample active_tab update", active_tab, child_svc);
    //     }
    //     catch {
    //         console.error("No child spawned for active_tab", active_tab)
    //     }





    // }, [active_tab]); // note: actor ref should never change

    // useEffect(() => {
    //     console.log("EditorSample mdtext update", mdtext);




    // }, [mdtext]); // note: actor ref should never change

    const onClose = (id: string) => svc.send({
        type: "EVENTS.FILE.CLOSE",
        id: id
    })

    const onSelect = (id: string) => svc.send({
        type: "EVENTS.TAB.ACTIVATE",
        id: id
    })


    const onUiSettingsChange = (key: string, value:string|boolean|number) => svc.send({
        type: "EVENTS.UI.UPDATE_SETTINGS",
        key: key,
        value: value
    })

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                background: "cadetblue",

                //  p: 2,
                display: "flex",
                flexDirection: "column",
            }}
        >
          

            <Box
                sx={{
                    // height: `${theme.spacing(8)}`,
                    background: "transparent",
                    overflowX: "auto",
                    overflowY: "hidden",
                    width: "100%"
                }}
            >

                <Stack spacing={1} direction="row" sx={{
                    height: "100%",
                    alignItems: "end"
                }}>
                    {
                        files.map(v =>
                            <CompoundTab key={v.id} title={makeLabel(v.id) || ""} subheader={v.id} onClose={() => onClose(v.id)} selected={v.id === active_tab} onSelect={() => onSelect(v.id)} />

                            // Array.from({length:3}).map((x,i)=>i).map(v=>  
                            //     <CompoundTab key={v} title={`Some Tab ${v}`}  subheader={`Some Tab Some Tab ${v}`}/>
                            // <Button variant="outlined" sx={{
                            //     whiteSpace:"nowrap",
                            //     overflow:"hidden",
                            //     textOverflow:"ellipsis",
                            //     minWidth:"150px",
                            //     maxWidth:"300px",
                            //     p:0,
                            //     m:0
                            // }}>{`SOme button ${v}`}</Button>

                        )
                    }


                    <div style={{
                        flexGrow: 1,
                        //   display: "inline-block",
                    }}></div>
                    <div style={{
                        //  display: "inline-block",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        flexGrow: 0,
                          minWidth: `${theme.spacing(10)}`,
                        height: "100%",
                        textAlign:"right",
                       // lineHeight: `${theme.spacing(4)}`
                        //  background: "maroon"
                    }}>

                        <IconButton aria-label="settings"
                            sx={{
                                m: 0,
                                p: 0
                            }}

                            onClick={(e) => {
                                // e.preventDefault()
                                e.stopPropagation()
                                //setShowEditor(!showEditor)
                                //console.log("IconButton.VerticalSplitIcon", active_tab)
                                onUiSettingsChange('editor__display',!ui_settings.editor.display)

                            }}
                        >
                            <VerticalSplitIcon color='inherit' />
                        </IconButton>


                        <IconButton aria-label="upload"
                            sx={{
                                m: 0,
                                p: 0
                            }}

                            onClick={(e) => {
                                // e.preventDefault()
                                e.stopPropagation()
                             
                                console.log("IconButton.FileUploadIcon")
                                getFile()

                            }}
                        >
                            <FileUploadIcon color='inherit' />
                        </IconButton>



                        <IconButton
                    
                        sx={{ m: 0,
                            p: 0,
                            ...(ui_settings.explorer.display && { display: 'none' }) }}

                      //  color="inherit"
                        onClick={()=>onUiSettingsChange('explorer__display', true)}>
                       <MenuIcon />
                    </IconButton>


                        <IconButton aria-label="settings"
                            sx={{
                                m: 0,
                                p: 0
                            }}

                            onClick={(e) => {
                                // e.preventDefault()
                                e.stopPropagation()
                                console.log("IconButton.MoreVertIcon")

                            }}
                        >
                            <MoreVertIcon color='inherit' />
                        </IconButton>
                    </div>
                </Stack>
            </Box>


            <div style={{
                flexGrow: 0,
                height: `${theme.spacing(1)}`,
                //     height:"100%"
            }}></div>
            
        {active_tab ? <ActiveTabContent svc={files.find(x => x.id === active_tab)!} showEditor={ui_settings.editor.display} /> :<NoOpenTabsView />}
           


            {/* </Box> */}

        </Box>
    )
}



export { EditorSample }