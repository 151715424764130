import { useEffect, useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

import { useSelector } from '@xstate/react';
import Divider from '@mui/material/Divider'
import ArticleIcon from '@mui/icons-material/Article';
import { GhRepoTreeContentItem } from '../../../../../types/app.types';
import {   type RepoMachineActorRef} from '../../../machines/getrepo';

function getFilePathWithoutFilename(fullPath: string): string {
  const regex = /(.*\/)[^\/]+$/;
  const match = regex.exec(fullPath);
  return match ? match[1] : "/";
}

function bytesToSize(bytes:number, decimals = 2) {
  if (!Number(bytes)) {
    return '0 Bytes';
  }

  const kbToBytes = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'B',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ];

  const index = Math.floor(
    Math.log(bytes) / Math.log(kbToBytes),
  );

  return `${parseFloat(
    (bytes / Math.pow(kbToBytes, index)).toFixed(dm),
  )} ${sizes[index]}`;
}


type CatalogViewFlatListGroupedProps = {
  mdfiles: Array<GhRepoTreeContentItem>,
  setActiveDoc: (path:string)=>void,
}

const CatalogViewFlatListGrouped = (props: CatalogViewFlatListGroupedProps) => {

  const {mdfiles, setActiveDoc} = props

 

  const [grouped, setGrouped] = useState<Record<string, Array<GhRepoTreeContentItem>>>({})

  const selected_document = {
    sha:"asdasdads"
  }
  useEffect(() => {
    if (!mdfiles.length) return

    let _grouped: Record<string, Array<GhRepoTreeContentItem>> = {}

    for (let i of mdfiles) {
      const path = getFilePathWithoutFilename(i.path)
      if (!(path in _grouped)) _grouped = { ..._grouped, [path]: [] }
      _grouped[path].push(i)

    }

    setGrouped(_grouped)

  }, [mdfiles])



  useEffect(() => {
    console.log("grouped updated", grouped)
  }, [grouped])

  return (

    Object.keys(grouped).map((path) => {

      const _id = crypto.randomUUID()
      return (
        <List
          key={path.replace(/\//g, '')}
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby={`settings-list-subheader-${_id}`}
          disablePadding
          subheader={
            <ListSubheader 
          
            component="div" 
            id={`settings-list-subheader-${_id}`}
            sx={{
              p:0,
              lineHeight:"normal"
            }}
            
            
            >
            <Typography variant='subtitle2' sx={{
              whiteSpace:"nowrap",
              overflow:"hidden",
              textOverflow:"ellipsis"
            }}>{path}</Typography>
            <Divider flexItem sx={{p:0}} />
            </ListSubheader>
          }
        >

          {grouped[path].map((item) => {

            return (
              <ListItemButton
                selected={item.sha === selected_document?.sha}
                key={item.sha}
              onClick={() => setActiveDoc(item.path)}
                divider
              >
                <ListItemIcon>
                  {/* <Avatar sx={{ bgcolor: deepPurple[500],  width: 24, height: 24, fontSize:14  }}>1</Avatar> */}
                  <ArticleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText 
                primary={item.path.split('/').pop()} 
                secondary={bytesToSize(item.size!)}
                primaryTypographyProps={{
                  variant:"subtitle2",
                  sx:{
                    whiteSpace:"nowrap",
                    overflow:"hidden",
                    textOverflow:"ellipsis"
                  }
                }}

                secondaryTypographyProps={{
                  variant:"body2"
                }}
                />
              </ListItemButton>)
          })}

        </List>
      )
    })









  );
}


export { CatalogViewFlatListGrouped }