import { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from "@mui/material"
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
 
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
 
import { styled, useTheme } from '@mui/material/styles';
import { GithubTree } from './sidebar/github/GithubTree';

import { MdEditorMachineContext } from "../machines/main";
import {GithubLocalModeToggle} from './components/GithibLocalModeToggle'
import {LocalFSMain} from './sidebar/localfs/Main'


const drawerWidth = 420;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


const MdEditorCustomLayout = () => {

    const theme = useTheme();
    const ui_settings = MdEditorMachineContext.useSelector((state) => state.context.ui_settings);
    const svc = MdEditorMachineContext.useActorRef();

    const handleDrawerOpen = () => {
        //setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        //  setDrawerOpen(false);
        onUiSettingsChange('explorer__display', false)

    };

    const toggleExplorerMode = () => {
        onUiSettingsChange('explorer__mode', ui_settings.explorer.mode === 'local' ? "github" : "local")
    };

    useEffect(() => {

        console.log("[MdEditorCustomLayout] ui_settings update", ui_settings)
    }, [ui_settings])

    const onUiSettingsChange = (key: string, value: string | boolean | number) => svc.send({
        type: "EVENTS.UI.UPDATE_SETTINGS",
        key: key,
        value: value
    })


    const setExplorerMode = (mode: "github" | "local") => {
        if(null === mode) return false
        onUiSettingsChange('explorer__mode',mode)
    };



    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "row",
                // background: "maroon",
                overflow: "hidden"
            }}
        >
            <Box
                sx={{
                    width: ui_settings.explorer.display ? `${drawerWidth}px` : 0,

                    //marginLeft: `${open?0:-drawerWidth}px`,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    //  background: "green",
                    overflow: "hidden"
                }}
            >
                <DrawerHeader>

                    <IconButton
                        edge="start"
                        sx={{ ml: 1, ...(!ui_settings.explorer.display && { display: 'none' }) }}

                        color="inherit"
                        onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                    <div style={{
                        flexGrow: 1,
                    }}></div>
                    <GithubLocalModeToggle  mode={ui_settings.explorer.mode} setMode={setExplorerMode}/>
                    {/* <IconButton
                        edge="end"
                        sx={{ ...(!ui_settings.explorer.display && { display: 'none' }) }}

                        color={ui_settings.explorer.mode === 'github' ? "info" : "inherit"}
                        onClick={toggleExplorerMode}>
                        <GitHubIcon />
                    </IconButton> */}

                </DrawerHeader>
                <Divider />

                {
                    ui_settings.explorer.mode === 'github' ? <GithubTree /> :
                    ui_settings.explorer.mode === 'local' ? <LocalFSMain /> :
                    null
                }

            </Box>

            <Box
                sx={{
                    flex: 1,
                    p: 1,
                    background: "cadetblue",
                    // width:"100%",
                    // maxWidth: `100%`,
                    overflow: "hidden"
                }}
            >
                <Outlet />
            </Box>

        </Box>
    )
}

export { MdEditorCustomLayout }