
import { useState, useEffect } from 'react'
import { useTheme, Paper } from '@mui/material'
import { Box } from "@mui/material"
import { useSelector } from '@xstate/react';
import { ActorRefFrom } from 'xstate'

import ReactMarkdown from "react-markdown";
import TextField from '@mui/material/TextField';
import { fetchFileMachine, type LoadFileMachineActorRef } from '../machines/mdfile';


import { MdFileWrapPaper } from '../../../components/markdown/MdFileWrapPaper';
import { MarkdownRender } from '../../../components/markdown/MarkdownRender';
import { useAppContext } from '../../../service/context/app/useAppContext';
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import rehypeHighlight from 'rehype-highlight'
import rehypeRaw from 'rehype-raw'
import rehypeSlug from 'rehype-slug'
import remarkGfm from 'remark-gfm'
import remarkToc from 'remark-toc'



type ActiveTabContentProps = {
    svc: ActorRefFrom<typeof fetchFileMachine>,
    showEditor: boolean
}

const ActiveTabContent = (props: ActiveTabContentProps) => {


    const rehypePlugins = [rehypeSlug, rehypeRaw, rehypeKatex]
    /** @type {PluggableList} */

    const { mode } = useAppContext()


    const remarkPlugins = [remarkToc, remarkGfm, remarkMath]


    const theme = useTheme()
    const { svc, showEditor } = props;


    const content = useSelector(svc, (s) => s.context.content);

    const [sample, setSample] = useState<string>("")

    useEffect(() => {
        console.log("[ActiveTabContent.useEffect] content updated", content?.length)
        if (null === content) return
        setSample(content)
    }, [content])

    return (

        content === null ?
            <Box
                component={"div"}
                sx={{

                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    height: "100%",
                    background: "magenta",

                }}
            >Loading...</Box> :
            <Box
                sx={{
                    height: `calc(100% - ${theme.spacing(9)})`,
                    width: `100%`,

                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center"

                }}
            >
                <Box
                    sx={{
                        display: showEditor ? "block" : "none",
                        flex: 1,
                        background: theme.palette.common.white,
                        overflow: "auto",
                        height: "100%",
                        maxWidth: "50%"
                    }}
                >
                    <TextField
                        sx={{
                            // maxHeight:`calc(50% - ${theme.spacing(8)}px)`,
                            height: "100%",
                            overflow: "auto"

                        }}
                        // label="Multiline"
                        multiline
                        fullWidth
                        //     rows={100}
                        value={sample}
                        variant="filled"
                        onChange={(event) => {

                            setSample(event.target.value)
                        }}
                    />
                </Box>
                <div style={{
                    flexGrow: 0,
                    width: `${theme.spacing(1)}`,
                    //     height:"100%"
                }}></div>
                <Box
                    sx={{
                        flex: 1,
                        background: theme.palette.common.white,
                        height: "100%",
                        maxWidth: showEditor ? "50%" : "80%",
                        overflow: "hidden",

                    }}
                >

                    <Box
                        component={"div"}
                        sx={{
                            // background: "yellow",
                            //    flex: 1,
                            height: "100%",
                            // /padding: theme.spacing(2),
                            //maxHeight:`calc(100% - ${theme.spacing(8)}px)`,
                            // maxHeight:"100%",
                            overflowY: "auto",
                            overflowX: "hidden",
                            p: 1,
                            // paddingBottom: `${theme.spacing(8)}px`,
                        }}
                    >

                        
                            <ReactMarkdown
                                // ref={mdRootRef}

                                //  data-theme={mode}
                                //className={`[data-theme="${mode}"]`}
                                className={`custom-md-root-${mode}`}
                                remarkPlugins={remarkPlugins}
                                rehypePlugins={rehypePlugins}
                            >{sample}
                            </ReactMarkdown>
                     



                    </Box>
                </Box>

            </Box>
    )
}

export { ActiveTabContent }