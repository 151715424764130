

import { useState, useEffect } from 'react'
import { useSelector } from '@xstate/react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { parseRepoLink } from '../../../machines/getrepo';
import { MdEditorMachineContext } from '../../../machines/main';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';
import {  RepoSubheaderIcons } from './CatalogTreeRepoTitle'
type ProjectsListSample = {
    title: string,
    path: string
}




const samples: ProjectsListSample[] = [
    { title: "sample1", path: "/mdoks/test1.mdok.json" },
    { title: "sample2", path: "/mdoks/test2.mdok.json" },
    { title: "sample3", path: "/mdoks/test3.mdok.json" },


]


const parseLink = (link: string) => {
    const groups = parseRepoLink(link)
    const [origin, username, repository, tree, path] = groups

    return {
        username: username,
        repository: repository,
        tree: tree ? tree : "default",
        path: path ? path : "/"
    }
}


type LocalFSProjectsListProps = {
    
  }

const LocalFSProjectsList = (props:LocalFSProjectsListProps)=>{


    const svc = MdEditorMachineContext.useActorRef();
    const localfs = MdEditorMachineContext.useSelector((state) => state.context.localfs);
    const selected_localfs = MdEditorMachineContext.useSelector((state) => state.context.selected_localfs);

    return (
        <List>
            {samples.map((item, index) => (
                <ListItem
                    key={index}
                    disablePadding

                >
                    <ListItemButton
                        selected={selected_localfs === item.path}
                        divider
                        onClick={() => {
                            const _doc = samples.find(x => x.title === item.title)
                            if (null !== _doc) {
                                //setActiveDoc(_doc as MdBuiltinSample)
                                svc.send({
                                    type: "EVENTS.LOCALFS.LOAD",
                                    config_link: item.path
                                })
                            }
                        }}

                    >
                        <ListItemIcon>
                            {localfs.find(x => x.id === item.path) ? <FolderOpenIcon /> : <FolderIcon />}
                        </ListItemIcon>
                        <ListItemText
                            primary={item.title}
                            secondary={item.path}

                            secondaryTypographyProps={{
                                variant: "body1",
                                component: "div"
                            }}

                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>)
}


export {LocalFSProjectsList}