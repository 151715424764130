import { assign, createActor, fromPromise, setup, ActorRefFrom } from 'xstate';
import type { TreeNode, CatalogTreeDisplaySettings, MdEditorUiSettings, ProjectConfigProps } from '../../../types/app.types';



  
interface MachineContext {
    count: number;
    path: string;
    name: string;
    config: ProjectConfigProps,
    tree: TreeNode[],
    ui_settings: MdEditorUiSettings
  }
  
  
  type MachineEvent =
    | {
        type: 'EVENTS.FILE.CREATE',
        name: string
    } | {
        type: 'EVENTS.FOLDER.CREATE',
        name: string
    }
  

    

async function loadConfig(path: string): Promise<ProjectConfigProps> {
    return new Promise((resolve, rej) => {
        console.log(`[loadConfig] promise started: ${path}`)

        return fetch(path, { cache: 'force-cache' })
        .then((response) => {
            if (response.ok) return response.json();
            else return Promise.reject("[loadConfig] Didn't fetch config correctly");
        })
        .then((json) => {
            console.log(`[loadConfig] promise success:`, json)
            resolve(json)
        })
        .catch((error) => console.error("[loadConfig] promise",error))
        .finally(() => console.log(`[loadConfig] promise finally:`))

    });
}

const localFSMachine = setup({
    types: {} as {
        context: MachineContext,
        events: MachineEvent
    },
    actors: {
        fetchConfigFile: fromPromise(({ input }: { input: {path:string} }) => loadConfig(input.path))
    },
    guards: {
        isEmpty: ({ context }) => {
          return !("tree" in context.config);
        },
      },
      
}).createMachine({
    id: "localfsproj",
    initial: 'idle',
    context: ({ input }) => ({
        name: 'New Project',
        path: (input as {path:string} ).path,
        count:0,
        config: {} as ProjectConfigProps,
        tree: [],
        ui_settings: {} as MdEditorUiSettings
      }),

    entry: [
        ({ event }) => console.log("localfsproj.entry", event),
        // assign({
        //     path: ({ input }) => input.path
        // })
    ],
    exit: [
        ({ event }) => console.log("localfsproj.exit", event),
    ],
    states: {
        idle: {
            entry: [
                ({ event }) => console.log("localfsproj.idle.entry", event),
            ],
            exit: [
                ({ event }) => console.log("localfsproj.idle.exit", event),
            ],
   
            always:{
                target:"loading",
                guard:"isEmpty"
            }
        },
        loading: {
            entry: [
                ({ event }) => console.log("localfsproj.loading.entry", event),
            ],
            exit: [
                ({ event }) => console.log("localfsproj.loading.exit", event),
            ],
            invoke: {
                src: 'fetchConfigFile',
                input: ({ context }) => ({ path: context.path }),
                onDone: {
                    target: 'success',
                    actions: assign(({ event }) => ({
                        config: event.output,
                        tree:    event.output.tree,
                        ui_settings:   event.output.ui_settings,
                        name: event.output.name
                    }))
                },
                onError: {
                    target:'failure',
                    actions:[
                        ({ event })=>console.log("fetchMdFile.failure", event)
                    ]
                }
            }
        },
        success: {

            entry: [
                ({ event }) => console.log("localfsproj.success.entry", event),
                assign({ count:  0 })
            ],
            exit: [
                ({ event }) => console.log("localfsproj.success.exit", event),
            ],
        },
        failure: {
            entry: [
                ({ event }) => console.log("localfsproj.failure.entry", event),
                assign({ count: ({ context }) => context.count + 1 })
            ],
            exit: [
                ({ event }) => console.log("localfsproj.failure.exit", event),
            ],
            after: {
                1000: {
                    target:  'loading',
                    guard: ({ context, event }) => context.count < 3
                },
              
            },
            // on: {
            //     RETRY: 'loading'
            // }
        }
    }
});



type LocalFSMachineActorRef = ActorRefFrom<typeof localFSMachine>
export { localFSMachine }
export type { LocalFSMachineActorRef }