
import { useState, useEffect } from 'react'
import { GithubCatalogTreeView } from '../catalogtree/LeftSidebarCatalogTree';
import { CatalogViewFlatListGrouped } from '../catalogtree/FlatListGrouped';
import { Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import { useSelector } from '@xstate/react';
import type { TreeNode, GhRepoTreeContentItem, ProjectConfigProps , TreeViewMode} from '../../../../../types/app.types';
 
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeModeToggle } from './TreeModeToggle'
//import {FileFolderCreateButtons} from './FileFolderCreateButtons'

import { WideRecursiveList } from '../catalogtree/WideListTree';

import { type LocalFSMachineActorRef } from '../../../machines/localfs';
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


 

function traverseTree(tree: TreeNode): Array<string> {
    const nodes: TreeNode[] = [tree];
    let currentNode: TreeNode | undefined;
    const output = []

    while ((currentNode = nodes.pop())) {
        //  console.log(`${currentNode.type}/${currentNode.path}`); // Process current node
        if (currentNode.type === 'blob') output.push(currentNode.path)
        for (const child of currentNode.children ?? []) {
            nodes.push(child);
        }
    }

    return output


}

type ProjectExplorerProps = {
    svc: LocalFSMachineActorRef,
    onFileOpen: (path: string) => void,
    backFn: ()=>void
    //LocalFSMachineActorRef
}

const ProjectExplorer = (props: ProjectExplorerProps) => {
    const theme = useTheme();

    const {svc,onFileOpen, backFn } = props


    const config = useSelector(svc, (state) => state.context.config)
    const [mdfiles, setMdfiles] = useState<GhRepoTreeContentItem[]>([])


    const [treeType, setTreeType] = useState<TreeViewMode>("flat")

    //const viewmode = useSelector(svc, (state) => state.context.config).ui_settings.tree_display_settings.viewMode

    function isValidConfig(data: ProjectConfigProps): data is ProjectConfigProps {
        return data.tree.length >= 0;
      }


      useEffect(()=>{
        try{
            
            const val =  config.ui_settings.tree_display_settings.viewMode
            setTreeType(val)
            console.log("ProjectExplorer config updated", val, config)
        }
        catch{
            console.log("ProjectExplorer config not updated", config)
        }
        
      },[config])



    const setActiveDoc = (path: string) => { }
    // path: string;
    // mode: string;
    // type: "blob" | "tree";
    // sha: string;
    // size?: number;
    // url: string;
    useEffect(() => {
        if(!("tree" in config)) return
       
        const files = traverseTree({
            type: "tree",
            path: "",
            children: config.tree
        })

        const random = crypto.randomUUID()
        const _mdfiles = files.map((s, i) => ({ path: s, size: 0, type: "blob", mode: "100755", sha: `${random}-${i}`, url: "" } as GhRepoTreeContentItem))
        setMdfiles(_mdfiles)
        // console.log("BuiltinTreeSample", files)
    }, [config])


    useEffect(() => {

        console.log("ProjectExplorer mdfiles updated", mdfiles)
    }, [mdfiles])

    //    const mdfiles: ({ event }) => event.output.tree.filter((item: GhRepoTreeContentItem) => /(\w+)\.(md|markdown)$/i.test(item.path))

 
    return (
        !config.tree? <Box>Loading</Box> :

        <Box
            sx={{
                height: "100%",
                overflowY: "auto"
            }}
        >
            <DrawerHeader>

                <IconButton
                    edge="start"
                    sx={{ ml: 1 }}

                    color="inherit"
                    onClick={backFn}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
                {/* <FileFolderCreateButtons /> */}
                <div style={{
                    flexGrow: 1,
                }}></div>
                <TreeModeToggle mode={treeType} setMode={setTreeType} />


            </DrawerHeader>
            <Divider />

            {
                treeType === 'flat' ? <CatalogViewFlatListGrouped mdfiles={mdfiles} setActiveDoc={(path: string) => { }} /> :
                treeType === 'wide' ? <WideRecursiveList 
                    setActiveDoc={setActiveDoc}
                    data={config.tree}
                    expanded={true}
                /> :
                treeType === 'compact' ? <GithubCatalogTreeView
                    setActiveDoc={setActiveDoc}
                    data={config.tree}
                    expanded={true}
                /> :
                null

            }

        </Box>



    )
}


export { ProjectExplorer }