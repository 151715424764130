import { setup, ActorRefFrom, assign, assertEvent, stopChild } from 'xstate';
import { createActorContext } from '@xstate/react';
import { fetchRepoMachine, parseRepoLink } from './getrepo';
import { fetchFileMachine } from './mdfile'
import {localFSMachine} from './localfs'
import type { TreeNode, MdEditorUiSettings, CatalogTreeDisplaySettings } from '../../../types/app.types';


const   catalog_tree_display_settings_defaults: CatalogTreeDisplaySettings = {
  viewMode: "compact",
  expanded: false,
  compact_tree: false,
  orderBy: "name"
}

const mdEditorUiSettingsDefaults: MdEditorUiSettings = {
  editor: {
    display: false
  },
  viewer: {
    width: "80%"
  },
  explorer: {
    display: true,
    mode: "local"
  },
  tabs: {
    display: true
  },
  tree_display_settings: catalog_tree_display_settings_defaults
}

interface MachineContext {
  count: number;
  user: { name: string };
  repos: ActorRefFrom<typeof fetchRepoMachine>[];
  files: ActorRefFrom<typeof fetchFileMachine>[];
  localfs: ActorRefFrom<typeof localFSMachine>[];
  active_tab: string | null,
  selected_repo: string | null,
  selected_localfs: string | null,
  ui_settings: MdEditorUiSettings
}


type MachineEvent =
  | {
    type: 'EVENTS.REPO.ADD',
    repo_link: string
  } | {
    type: 'EVENTS.REPO.DELETE',
    repo_link: string
  } | {
    type: 'EVENTS.REPO.SET_ACTIVE',
    id: string | null
  } | {
    type: 'EVENTS.AUTH.ACCESS_TOKEN.SET',
    access_token: string
  } | {
    type: 'EVENTS.FILE.OPEN',
    path: string
  } | {
    type: 'EVENTS.FILE.CLOSE',
    id: string
  } | {
    type: 'EVENTS.TAB.ACTIVATE',
    id: string
  } | {
    type: 'EVENTS.UI.UPDATE_SETTINGS',
    key: string,
    value: string | boolean | number
  } | {
    type: 'EVENTS.LOCALFS.LOAD',
    config_link: string
  }  | {
    type: 'EVENTS.LOCALFS.SET_ACTIVE',
    id: string | null
  } | {
    type: 'EVENTS.LOCALFS.INIT',
    config_link: string
  }  






const mdEditorMachine = setup({
  types: {} as {
    context: MachineContext,
    events: MachineEvent
  },
  actions: {
    log: (_, params?: { message: string }) => {
      console.log(`[log]: ${params ? params.message : "empty"}`,);
    },
  },

}).createMachine({
  id: "mdeditor",
  systemId: 'mdeditor',
  context: {
    user: {
      name: 'David'
    },
    repos: [],
    files: [],
    localfs:[],
    count: 0,
    active_tab: null,
    selected_repo: null,
    selected_localfs:null,
    ui_settings: mdEditorUiSettingsDefaults
  },
  // ...
  entry: ({ event }) => console.log("mdeditor.entry", event),
  exit: ({ event }) => console.log("mdeditor.exit", event),
  initial: "idle",
  on: {
    'EVENTS.UI.UPDATE_SETTINGS': {
      actions: [
        ({ event }) => console.log(event),

        assign({

          ui_settings: ({ context, event }) => {
            // const remain_tabs = context.files.filter((item, index) => item.id !== event.id)
            const current_settings = Object.assign({}, context.ui_settings)
            const path = event.key.split("__")
            if (path.length !== 2) return current_settings
            const [_root, _prop] = path

            //@ts-ignore
            current_settings[_root][_prop] = event.value


            return current_settings
          }
        })
      ]
    },
    'EVENTS.FILE.OPEN': {
      // guard: ({ context, event }) => context.files.find(x => x.id === event.path) !== null,
      target: ".open_file"
    },
    'EVENTS.FILE.CLOSE': {
      actions: [
        ({ event }) => console.log(event),
        stopChild(({ event }) => event.id),
        assign({
          files: ({ context, event }) =>
            context.files.filter((item, index) => item.id !== event.id),
          active_tab: ({ context, event }) => {
            const remain_tabs = context.files.filter((item, index) => item.id !== event.id)
            if (remain_tabs.length) {
              return remain_tabs[0].id
            }
            return null
          }
        })
      ]
    },
    'EVENTS.TAB.ACTIVATE': {
      guard: ({ context, event }) => context.files.find(x => x.id === event.id) !== null,
      actions: [
        ({ event }) => console.log(event),
        assign({
          active_tab: ({ event }) => event.id
        })
      ]
    },
    'EVENTS.REPO.ADD': [{
      target: ".load_repo",
      guard: ({ context, event }) => {
        //    const found = context.repos.find(x=>x.id = event.repo_link)
        const resp = parseRepoLink(event.repo_link)
        return (resp.length === 5 || resp.length === 3)
      }
    },


      // {
      //   target: ".idle",
      //   guard: ({context, event})=>{
      //     const found = context.repos.find(x=>x.id = event.repo_link)
      //     return found !== null
      //   },
      //   actions:[
      //     assign(
      //       ({  event }) => ({
      //         selected_repo: event.repo_link
      //       })
      //     ),
      //   ]
      // }

    ],
    'EVENTS.REPO.SET_ACTIVE': [
      {
        actions: [
          assign(
            ({ event }) => ({ selected_repo: event.id  })
          )
        ],
       // guard: ({ event }) => event.id !== null
      },

    ],
    'EVENTS.LOCALFS.LOAD' : {
      actions: [
        ({ event }) => console.log(event),
        assign({
          selected_localfs: ({ event }) => event.config_link
        })
      ],
      target:".load_localfs"
    },
    'EVENTS.LOCALFS.SET_ACTIVE':{
      actions: [
        ({ event }) => console.log(event),
        assign({
          selected_localfs: ({ event }) => event.id
        })
      ],
    }
  },
  states: {
    idle: {
      entry: ({ event }) => console.log("mdeditor.idle.entry", event),
      exit: ({ event }) => console.log("mdeditor.idle.exit", event),


    },
    open_file: {
      entry: [
        // ({event})=>({ type: "log" ,params:{message:`mdeditor.open_file.entry, ${JSON.stringify(event)}`}  }),
        ({ event }) => console.log("mdeditor.open_file.entry", event),
        assign({
          files: ({ context, event, spawn }) => {
            assertEvent(event, 'EVENTS.FILE.OPEN');
            const exists = context.files.find(x => x.id === event.path)
            console.log("mdeditor.open_file.assign", context.files, event.path, exists)
            if (exists) {
              return context.files
            }

            return [...context.files, spawn(fetchFileMachine,
              {
                id: event.path,
                input: {
                  path: event.path,
                  content: null
                }
              })]
          },
          active_tab: ({ event }) => {
            assertEvent(event, 'EVENTS.FILE.OPEN');
            return event.path
          }
        })
      ],
      exit: ({ event }) => console.log("mdeditor.open_file.exit", event),


      // after:{
      //   1000 : "idle"
      // }

    },
    load_repo: {
      entry: [
        ({ event }) => console.log("mdeditor.load_repo.entry", event),
        assign(
          ({ context, event, spawn }) => {
            assertEvent(event, 'EVENTS.REPO.ADD');
            const { repo_link } = event
            const exists = context.repos.find(x => x.id === repo_link)
            if (exists) return {
              selected_repo: repo_link
            }

            return {
              repos: [...context.repos, spawn(fetchRepoMachine,
                {
                  id: event.repo_link,
                  input: {
                    link: repo_link,
                    access_token: undefined
                  }
                })],
              selected_repo: repo_link
            }
          }
        ),


      ],
      exit: ({ event }) => console.log("mdeditor.load_repo.exit", event),
    },
    load_localfs :{
      entry: [
        ({ event }) => console.log("mdeditor.load_localfs.entry", event),
        assign(
          ({ context, event, spawn }) => {

            assertEvent(event, 'EVENTS.LOCALFS.LOAD');
            const exists = context.localfs.find(x => x.id === event.config_link)
            console.log("mdeditor.load_localfs.assign", context.localfs, event.config_link, exists)

            if (exists) return {
              
            }

            return  {
              
              localfs: [...context.localfs, spawn(localFSMachine,
                {
                  id: event.config_link,
                  input: {
                    path: event.config_link,
                    content: null
                  }
                })]
            }
          }

        
          
        )
    ],
      exit: ({ event }) => console.log("mdeditor.load_localfs.exit", event),
    }
  }
});



export const MdEditorMachineContext = createActorContext(mdEditorMachine);