import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import type { TreeViewMode, TreeNode } from '../../../../../../types/app.types';
import ArticleIcon from '@mui/icons-material/Article';
import FolderIcon from '@mui/icons-material/Folder';


//https://stackoverflow.com/a/2117523/592737
function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    ) as string;
}




  


type EditableTreeProps = {
    setActiveDoc: (path: string) => void,
    data: TreeNode[],
    expanded: boolean,
    selected: string,
    setSelected:(s:string)=>void
}




type CollapsibleBlockProps = {
    path: string,
    expanded:boolean,
    children?: React.ReactNode,
    selected: string,
    setSelected:(s:string)=>void
}
const CollapsibleBlock = ({ children, path, expanded, selected, setSelected }: CollapsibleBlockProps) => {

    const [open, setOpen] = React.useState(expanded);
    const handleClick = () => {

        setOpen(!open);
        setSelected(path)
    };
    return (
        <>

            <ListItemButton onClick={handleClick} divider selected={path === selected}>
                <ListItemIcon>
                    <FolderIcon />
                </ListItemIcon>
                <ListItemText primary={path.split('/').pop()} />
                {open ?   <ExpandMore  fontSize="small" /> : <KeyboardArrowRightIcon   fontSize="small" />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{
                    pl: 1
                }}>
                    {children}
                </List>
            </Collapse>
        </>
    )
}

const EditableTree = (props: EditableTreeProps) => {



    const { data , setActiveDoc, expanded, selected, setSelected} = props

    const _id = uuidv4()

    
    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby={`nested-list-subheader-${_id}`}
            // subheader={
            //     <ListSubheader component="div" id={`nested-list-subheader-${_id}`}>
            //         Nested List Items
            //     </ListSubheader>
            // }
        >


            {data.map(item => (
                <React.Fragment
                    key={uuidv4()}
                >
                    {item.type === 'tree' ?
                        <CollapsibleBlock
                             path={item.path}
                            expanded={expanded}
                            selected={selected}
                            setSelected={setSelected}
                        >
                            <EditableTree
                                data={item.children ?? []}
                                expanded={expanded}
                                setActiveDoc={setActiveDoc}
                                selected={selected}
                                setSelected={setSelected}
                            />
                        </CollapsibleBlock> :
                        <ListItemButton divider={true} onClick={()=>setSelected(item.path)} selected={item.path === selected}>
                        <ListItemIcon>
                            <ArticleIcon />
                        </ListItemIcon>
                        <ListItemText primary={item.path.split('/').pop()} />
                    </ListItemButton>
                    }
                </React.Fragment>
            ))}





        </List>
    );
}


export { EditableTree }