
import { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { green, pink } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Paper } from '@mui/material'
import { FolderSelector } from './FolderSelect'
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { TreeNode } from '../../../../../../types/app.types';


type AddAssetFormProps = {
    folders:Array<string>,
    type: "blob" | "tree",
    saveFn:(item:TreeNode)=>void,
    closeFn:()=>void,
    selected:string
}

const AddAssetForm = (props:AddAssetFormProps)=>{

    const {folders, type, saveFn, closeFn,selected} = props
    const [name, setName] = useState<string>("")
    const [selectedFolder, setSelectedFolder] = useState<string>(selected.length?selected:"/")


    const onSaveClick = ()=>saveFn(
        {
            type: type, 
            path:selectedFolder==='/'?name:`${selectedFolder}/${name}`, 
            ...(type === 'tree' && {children: []})
        }
    )

    return (
        <Paper
        sx={{
            p:1,
            m:1,
        }}

        variant='outlined'
    >
       
        <List sx={{ p: 1, bgcolor: 'background.paper' }}>
            <ListItem

                secondaryAction={
                    <IconButton edge="end" aria-label="close" onClick={closeFn} >
                        <CloseIcon />
                    </IconButton>
                }
                disablePadding
            >

                <ListItemAvatar>
                    <Avatar sx={{ bgcolor: green[500], width: 32, height: 32 }} >
                        {type === 'blob'?<AssignmentIcon fontSize='small' />:type === 'tree'?<FolderIcon  fontSize='small' />:null}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText  primary={type === 'blob'?'New file':type === 'tree'?'New folder':null}  primaryTypographyProps={{
                    variant: "button"
                }}/>

            </ListItem>
        </List>
        <Stack direction="column" spacing={0}>
            <Box
                sx={{

                    p: 1
                }}
            >
                <TextField fullWidth label="Name" value={name} onChange={(e)=>setName(e.target.value.trim())} required />
            </Box>
            <Box
                sx={{

                    p: 1
                }}
            >
                <FolderSelector folders={folders} onSelect={setSelectedFolder}/>
            </Box>
            <Box
                sx={{

                    p: 1
                }}
            >
                <Stack direction="row-reverse" spacing={2}>
                    {/* <Button variant="outlined" color="error">Cancel</Button> */}
                    <div style={{
                        flexGrow: 1,
                    }}></div>
                    <Button 
                        disabled={name.length === 0}
                        variant="outlined" 
                        onClick={onSaveClick}
                    >
                        Create
                    </Button>
                </Stack>

            </Box>




        </Stack>
    </Paper>
    )
}


export { AddAssetForm }