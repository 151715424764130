
import { useState, useEffect } from 'react'


import { Box } from '@mui/material'
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { GitHub } from '@mui/icons-material';


import { MdEditorMachineContext } from '../../../machines/main';
import { type LocalFSMachineActorRef } from '../../../machines/localfs';
import { LocalFSProjectForm } from './form/ProjectForm'
import { ProjectExplorer } from './ProjectExplorer'
import { LocalFSProjectsList } from './ProjectsList'



const LocalFSMain = () => {

    const [showProjectForm, setShowProjectForm] = useState<boolean>(false)
    const theme = useTheme()
    const selected_localfs = MdEditorMachineContext.useSelector((state) => state.context.selected_localfs);
    const svc = MdEditorMachineContext.useActorRef();
    const localfssvc = MdEditorMachineContext.useSelector((state) => selected_localfs ? state.children[selected_localfs] : undefined);



    const onFileOpen = (path: string) => svc.send({
        type: "EVENTS.FILE.OPEN",
        path: path
    })



    const onLocalFSDeselect = () => svc.send({
        type: "EVENTS.LOCALFS.SET_ACTIVE",
        id: null
    })


    useEffect(() => {

        if (showProjectForm === true) {
            onFileOpen('/docs/remark-gfm.md')
        }
    }, [showProjectForm])

    return (
        <Box
            sx={{

                height: "100%"
            }}
        >
            {
                selected_localfs ?
                    <Box
                        sx={{
                            height: `calc(100% - ${theme.spacing(8)})`,
                            overflowY: "auto"
                        }}
                    >
                        <ProjectExplorer
                            svc={localfssvc as LocalFSMachineActorRef}
                            onFileOpen={(path: string) => console.log("ProjectExplorer.onFileOpen called", path)}
                            backFn={onLocalFSDeselect}
                        />

                    </Box> 
                : showProjectForm ?
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                p: 1
                            }}
                        >
                            <LocalFSProjectForm onClose={() => setShowProjectForm(false)} />


                        </Box>
                :

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%"
                            }}
                        >
                            <LocalFSProjectsList />
                            <div style={{
                                flexGrow: 1,
                            }}></div>
                            <Box
                                sx={{
                                    p: 1
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    startIcon={<GitHub />}
                                    fullWidth
                                    onClick={() => setShowProjectForm(!showProjectForm)}

                                >
                                    Add Project
                                </Button>
                            </Box>
                        </Box>
            }
        </Box>
    )
}


export { LocalFSMain }