

import { useState } from 'react';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';

import { LeftSidebarTopControls } from './LeftSidebarTopControls';
import {   type RepoMachineActorRef} from '../../../machines/getrepo';

type SettingsBarCollapsibleWrapperProps = {
    svc: RepoMachineActorRef
  }
  



const SettingsBarCollapsibleWrapper = (props:SettingsBarCollapsibleWrapperProps) => {
    const { svc } = props
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    return (


        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="settings-list-subheader"
            disablePadding
            subheader={
                <ListSubheader component="div" id="settings-list-subheader" sx={{
                    p: 0
                }}>

                    <LeftSidebarTopControls svc={svc} />


                </ListSubheader>
            }
        >




        </List>




    )
}


export { SettingsBarCollapsibleWrapper }