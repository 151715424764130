

import { useState, useEffect } from 'react'
import { useSelector } from '@xstate/react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { parseRepoLink } from '../../../machines/getrepo';
import { MdEditorMachineContext } from '../../../machines/main';

import {  RepoSubheaderIcons } from './CatalogTreeRepoTitle'
type ReposBuiltinSample = {
    title: string,
    path: string
}




const samples: ReposBuiltinSample[] = [
    { title: "strimzi", path: "https://github.com/strimzi/strimzi-kafka-operator/tree/0.39.0" },
    { title: "pydantic-core", path: "https://github.com/jgraph/drawio-desktop/tree/v22.1.21/doc/md/hello/" },
    { title: "react-markdown", path: "https://github.com/remarkjs/react-markdown" },
    { title: "highlight", path: "https://github.com/highlightjs/highlight.js" },
    { title: "highlight3", path: "https://github.com/highlightjs/highlight.js/doc/md/hello/" },
    { title: "es6", path: "https://github.com/bevacqua/es6" }
]


const parseLink = (link: string) => {
    const groups = parseRepoLink(link)
    const [origin, username, repository, tree, path] = groups

    return {
        username: username,
        repository: repository,
        tree: tree ? tree : "default",
        path: path ? path : "/"
    }
}


type GithubRepositoryListProps = {
    
  }

const GithubRepositoryList = (props:GithubRepositoryListProps)=>{


    const svc = MdEditorMachineContext.useActorRef();
    const repos = MdEditorMachineContext.useSelector((state) => state.context.repos);
    const selected_repo = MdEditorMachineContext.useSelector((state) => state.context.selected_repo);

    return (
        <List>
            {samples.map((item, index) => (
                <ListItem
                    key={index}
                    disablePadding

                >
                    <ListItemButton
                        selected={selected_repo === item.path}
                        divider
                        onClick={() => {
                            const _doc = samples.find(x => x.title === item.title)
                            if (null !== _doc) {
                                //setActiveDoc(_doc as MdBuiltinSample)
                                svc.send({
                                    type: "EVENTS.REPO.ADD",
                                    repo_link: item.path
                                })
                            }
                        }}

                    >
                        <ListItemIcon>
                            {repos.find(x => x.id === item.path) ? <CloudDoneIcon /> : <CloudDownloadIcon />}
                        </ListItemIcon>
                        <ListItemText
                            primary={`${parseLink(item.path).username}/${parseLink(item.path).repository}`}
                            secondary={<RepoSubheaderIcons {...parseLink(item.path)} />}

                            secondaryTypographyProps={{
                                variant: "body1",
                                component: "div"
                            }}

                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>)
}


export {GithubRepositoryList}