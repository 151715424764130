import { useEffect } from 'react';
import CardHeader from '@mui/material/CardHeader';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { red, green, blue, blueGrey } from '@mui/material/colors';
import LabelIcon from '@mui/icons-material/Label';
import CloseIcon from '@mui/icons-material/Close';
//import { GhRepoParserMachineContext } from "../ghrepomachine";
import { fetchRepoMachine, type RepoMachineActorRef } from '../../../machines/getrepo';
import Divider from '@mui/material/Divider';
import GitHubIcon from '@mui/icons-material/GitHub';
import { ActorRefFrom } from 'xstate'
import { useSelector } from '@xstate/react'
import { Box, Typography } from '@mui/material'

import FolderOpenIcon from '@mui/icons-material/FolderOpen';
type RepoSubheaderIconsProps = {
  tree: string | undefined,
  path: string | undefined
}
export const RepoSubheaderIcons = (props: RepoSubheaderIconsProps) => {
  const { tree, path } = props
  return (
    <Box
      sx={{
        display: "flex",
        width:"100%",
        justifyContent:"start"
      }}
    >
     <Box sx={{ display: "flex", pr:1 }}><LabelIcon fontSize='small' /><Typography variant='subtitle2'>{tree?tree:"default"}</Typography></Box>
     <Box sx={{ display: "flex" }}><FolderOpenIcon fontSize='small' /><Typography variant='subtitle2'>{path?path:"/"}</Typography></Box>
    </Box>



  )
}

type CatalogTreeRepoTitleProps = {
  svc: RepoMachineActorRef,
  onClose: () => void
}
const CatalogTreeRepoTitle = (props: CatalogTreeRepoTitleProps) => {


  const { svc, onClose } = props

  const { details, username, repository, tree, path } = useSelector(svc, (state) => ({
    details: state.context.details,
    username: state.context.username,
    repository: state.context.repository,
    tree: state.context.tree,
    path: state.context.path,
  }))

  useEffect(() => {
    console.log({ details, username, repository, tree, path })
  }, [details, username, repository, tree, path])

  // const { full_name, pushed_at } = repo_details

  return (
    <CardHeader
      sx={{
        width: "100%",
        //py: 0
      }}
      avatar={
        <Avatar sx={{ bgcolor: blue[500] }}  >
          <GitHubIcon />
        </Avatar>
      }
      action={
        <IconButton aria-label="settings" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      }
      title={`${username}/${repository}`}
     subheader={<RepoSubheaderIcons tree={tree} path={path} />}
      
      titleTypographyProps={{
        variant: "button"
      }}

      subheaderTypographyProps={{
        variant: "body1",
        component: "div"
      }}
    />
  );
}

export { CatalogTreeRepoTitle }
