import { SettingsBarCollapsibleWrapper } from './SettingBarCollapsibleWrapper';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import { Box } from "@mui/material";
import Divider from '@mui/material/Divider';
import { CatalogViewFlatListGrouped } from './FlatListGrouped'
import { GithubCatalogTreeView } from "./LeftSidebarCatalogTree";
import { useSelector } from '@xstate/react';
import { type RepoMachineActorRef } from '../../../machines/getrepo';
import { useEffect, useState } from 'react';
import { TreeNode } from '../../../../../types/app.types';

import { WideRecursiveList } from './WideListTree'

type LeftSidebarCatalogMainProps = {
  svc: RepoMachineActorRef,
  onFileOpen: (path: string) => void
}


const LeftSidebarCatalogMain = (props: LeftSidebarCatalogMainProps) => {

  const { svc, onFileOpen } = props
  const catalog_tree_display_settings = useSelector(svc, (state) => state.context.catalog_tree_display_settings)
  const files_tree = useSelector(svc, (state) => state.context.files_tree)
  const files_tree_compact = useSelector(svc, (state) => state.context.files_tree_compact)
  const mdfiles = useSelector(svc, (state) => state.context.mdfiles)

  const [treeData, setTreeData] = useState<TreeNode[]>([])
  useEffect(() => {
    const _data = catalog_tree_display_settings.compact_tree ? (files_tree_compact.children || []) : (files_tree.children || [])
    setTreeData(_data)
  }, [catalog_tree_display_settings, files_tree_compact, files_tree])


  useEffect(() => {
    // traverseTree({
    //     type:"tree",
    //     path:"",
    //     children: treeData
    // })
  }, [treeData])

  const { details, username, repository, tree, path } = useSelector(svc, (state) => ({
    details: state.context.details,
    username: state.context.username,
    repository: state.context.repository,
    tree: state.context.tree,
    path: state.context.path,
  }))




  const setActiveDoc = (path: string) => onFileOpen(`https://raw.githubusercontent.com/${username}/${repository}/${tree}/${path}`)

  //   const [viewMode, setViewMode] = useState<'flat' | 'tree'>('tree')
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        p: 1
      }}
    >

      <List
        sx={{ width: '100%', bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="settings-list-subheader"
        disablePadding
        subheader={
          <ListSubheader component="div" id="settings-list-subheader" sx={{
            p: 0
          }}>
            <SettingsBarCollapsibleWrapper svc={svc} />
            <Divider />


          </ListSubheader>
        }
      >
        {
          catalog_tree_display_settings.viewMode === 'flat' ? <CatalogViewFlatListGrouped mdfiles={mdfiles} setActiveDoc={setActiveDoc} /> :
            catalog_tree_display_settings.viewMode === 'compact' ? <GithubCatalogTreeView

              setActiveDoc={setActiveDoc}
              data={treeData}
              expanded={catalog_tree_display_settings.expanded}
            /> :
              catalog_tree_display_settings.viewMode === 'wide' ? <WideRecursiveList
                setActiveDoc={setActiveDoc}
                data={treeData}
                expanded={catalog_tree_display_settings.expanded}
              /> :
                null


        }
      </List>
    </Box>
  )
}



export { LeftSidebarCatalogMain }