import { assign, createActor, fromPromise, setup, ActorRefFrom } from 'xstate';


interface MachineContext {
    count: number;
    path: string;
    name: string;
    content: string | null;
  }
  
  
  type MachineEvent =
   | {
      type: 'EVENTS.FILE.OPEN',
      path: string
    }
  

    

async function fetchFile(path: string): Promise<{ content: string }> {
    return new Promise((resolve, rej) => {
        console.log(`fetchFile promise started: ${path}`)

        return fetch(path, { cache: 'force-cache' })
        .then((response) => {
            if (response.ok) return response.text();
            else return Promise.reject("Didn't fetch text correctly");
        })
        .then((text) => {
            console.log(`fetchFile promise success: ${text.length}`)
            resolve({content: text})
        })
        .catch((error) => console.error("fetchFile promise",error))
        .finally(() => console.log(`fetchFile promise finally:`))

    });
}

const fetchFileMachine = setup({
    types: {} as {
        context: MachineContext,
        events: MachineEvent
    },
    actors: {
        fetchMdFile: fromPromise(({ input }: { input: {path:string} }) => fetchFile(input.path))
    },
    guards: {
        isEmpty: ({ context }) => {
          return context.content  === null;
        },
      },
      
}).createMachine({
    id: "mdfilemgmt",
    initial: 'idle',
    context: ({ input }) => ({
        name: 'World',
        path: (input as {path:string} ).path,
        content:null,
        count:0
      }),

    // context:{
    //     name: 'World',
    //     data: null,
    //     path: "",
    //     content:null,
    //     count:0
    //   },
    entry: [
        ({ event }) => console.log("mdfilemgmt.entry", event),
        // assign({
        //     path: ({ input }) => input.path
        // })
    ],
    exit: [
        ({ event }) => console.log("mdfilemgmt.exit", event),
    ],
    states: {
        idle: {
            entry: [
                ({ event }) => console.log("mdfilemgmt.idle.entry", event),
            ],
            exit: [
                ({ event }) => console.log("mdfilemgmt.idle.exit", event),
            ],
   
            always:{
                target:"loading",
                guard:"isEmpty"
            }
        },
        loading: {
            entry: [
                ({ event }) => console.log("mdfilemgmt.loading.entry", event),
            ],
            exit: [
                ({ event }) => console.log("mdfilemgmt.loading.exit", event),
            ],
            invoke: {
                src: 'fetchMdFile',
                input: ({ context }) => ({ path: context.path }),
                onDone: {
                    target: 'success',
                    actions: assign({
                        content: ({ event }) => event.output.content
                    })
                },
                onError: {
                    target:'failure',
                    actions:[
                        ({ event })=>console.log("fetchMdFile.failure", event)
                    ]
                }
            }
        },
        success: {

            entry: [
                ({ event }) => console.log("mdfilemgmt.success.entry", event),
                assign({ count:  0 })
            ],
            exit: [
                ({ event }) => console.log("mdfilemgmt.success.exit", event),
            ],
        },
        failure: {
            entry: [
                ({ event }) => console.log("mdfilemgmt.failure.entry", event),
                assign({ count: ({ context }) => context.count + 1 })
            ],
            exit: [
                ({ event }) => console.log("mdfilemgmt.failure.exit", event),
            ],
            after: {
                1000: {
                    target:  'loading',
                    guard: ({ context, event }) => context.count < 3
                },
              
            },
            // on: {
            //     RETRY: 'loading'
            // }
        }
    }
});



type LoadFileMachineActorRef = ActorRefFrom<typeof fetchFileMachine>
export { fetchFileMachine }
export type { LoadFileMachineActorRef }