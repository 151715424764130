import { Box } from "@mui/material"
import { DndSample1 } from "../dnd/Sample1"
import CssBaseline from '@mui/material/CssBaseline';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Container from '@mui/material/Container';
import { DnDContainer } from "../dnd/Container";
const NoOpenTabsView = () => {

    return (
        <Box
            sx={{
                height: "100%",
                display: "flex",
                background: "wheat",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
        <DndProvider backend={HTML5Backend}> 
            <Container maxWidth="xl" sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent:"space-around"
            }}>
                <Box sx={{ bgcolor: '#cfe8fc', overflow: "hidden" }}>

                    <DnDContainer />

                </Box>
          

                <Box sx={{ bgcolor: '#cfe8fc', overflow: "hidden" }}>

                    <DnDContainer />

                </Box>

                <Box sx={{ bgcolor: '#cfe8fc', overflow: "hidden" }}>

<DnDContainer />

</Box>
            </Container>
        </DndProvider>


        </Box >
    )
}

export { NoOpenTabsView }