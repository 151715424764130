import { assign, createActor, fromPromise, setup, ActorRefFrom, assertEvent, sendTo } from 'xstate';
import type { GhRepoTree, GhRepoBranch, GhRepoDetails, GhRepoTreeContentItem, TreeNode, CatalogTreeDisplaySettings } from '../../../types/app.types';



 
interface MachineContext {
  access_token: string | undefined;
  username: string;
  repository: string;
  tree: string | undefined,
  path: string | undefined,
  details: GhRepoDetails;
  branches: GhRepoBranch[],
  content_tree: GhRepoTree
  files_tree: TreeNode,
  files_tree_compact: TreeNode,
  mdfiles: GhRepoTreeContentItem[],
  catalog_tree_display_settings: CatalogTreeDisplaySettings,
  use_default_branch: boolean
}

interface MachineInput {
  access_token: string | undefined;
  link: string

}

type MachineEvent =
  | {
    type: 'xstate.init',
    input: MachineInput
  } | {
    type: 'EVENTS.UI.CATALOG_TREE.UPDATE_DISPLAY_SETTINGS_VALUE',
    key: keyof CatalogTreeDisplaySettings,
    value: CatalogTreeDisplaySettings[keyof CatalogTreeDisplaySettings]
  } | {
    type: 'EVENTS.UI.SET_ACTIVE_DOC',
    path: string | undefined
  }








let catalog_tree_display_settings_defaults: CatalogTreeDisplaySettings = {
  viewMode: "compact",
  expanded: false,
  compact_tree: false,
  orderBy: "name"
}






const USE_FAKE_DATA = false;


const getRepoDetails = async (username: string, repository: string, access_token?: string) => new Promise<GhRepoDetails>(async (resolve, reject) => {


  if (USE_FAKE_DATA) {

    const response = await fetch('/fake_data/repo_details.json');
    const _json = await response.json();
    resolve(_json)
    return
  }

  const endpoint = `https://api.github.com/repos/${username}/${repository}`
  const data = await getApiRequest(endpoint, access_token) as GhRepoDetails;
  resolve(data)

})


const getRepoBranches = (username: string, repository: string, access_token: string) => new Promise<GhRepoBranch[]>(async (resolve, reject) => {

  const endpoint = `https://api.github.com/repos/${username}/${repository}/branches`
  const data = await getApiRequest(endpoint, access_token) as GhRepoBranch[];
  resolve(data)

})


const getRepoTreeRecursive = (details: GhRepoDetails, access_token?: string,) => new Promise<GhRepoTree>(async (resolve, reject) => {

  if (USE_FAKE_DATA) {

    const response = await fetch('/fake_data/repo_tree.json');
    const _json = await response.json();
    resolve(_json)
    return
  }

  const headers = new Headers();
  headers.append("accept", "application/vnd.github+json");
  headers.append("X-GitHub-Api-Version", "2022-11-28");

  //const { access_token, details } = _
  const recursive = true

  if (undefined !== access_token) {
    const bearer = `Bearer ${access_token}`;
    headers.append("Authorization", bearer);
  }


  const { trees_url, default_branch } = details

  const endpoint = `${trees_url.replace('{/sha}', '/' + default_branch)}`



  const response = await fetch(`${endpoint}?recursive=${recursive}`, {
    method: "GET",
    headers: headers,
    cache: "force-cache"
  });


  console.log(`[getRepoTreeRecursive]: ${endpoint}: ${response.ok}, ${response.status}`)

  if (!response.ok) reject("[HANDLEME!]response.ok is not true")
  if (!(response.status === 200)) reject("[HANDLEME!]response.status is not 200")


  const data = await response.json();

  resolve(data)


})


const getApiRequest = (endpoint: string, token?: string) => new Promise(async (resolve, reject) => {


  const headers = new Headers();
  headers.append("accept", "application/json");

  if (undefined !== token) {
    const bearer = `Bearer ${token}`;
    headers.append("Authorization", bearer);
  }


  const response = await fetch(endpoint, {
    method: "GET",
    headers: headers
  });


  console.log(`[getApiRequest]: ${endpoint}: ${response.ok}, ${response.status}`)

  if (!response.ok) reject("[HANDLEME!]response.ok is not true")
  if (!(response.status === 200)) reject("[HANDLEME!]response.status is not 200")


  const data = await response.json();

  resolve(data)


})

export const parseRepoLink = (repo_link: string) => {
  const addr = repo_link.trim()

  const groups_tree = addr.match(/https\:\/\/github\.com\/([\w.-]+)\/([\w.-]+)\/tree\/([\w.-]+)\/?(.*)/)
  const groups_singlerepo = addr.match(/https\:\/\/github\.com\/([\w.-]+)\/([\w.-]+)/)


  // console.log({
  //   groups_tree,
  //   groups_singlerepo
  // })
  if (groups_tree?.length === 5) return groups_tree
  if (groups_singlerepo?.length === 3) return groups_singlerepo


  return []

}




function optimizeTreeNode(tree: TreeNode) {

  const cloned_tree = JSON.parse(JSON.stringify(tree));
  const nodes = [cloned_tree];


  let counter = 0
  let prev_node: TreeNode | undefined = undefined
  let currentNode: TreeNode | undefined = undefined
  let dest_node: TreeNode | undefined = undefined

  while ((currentNode = nodes.pop())) {
    let is_child = prev_node?.children && prev_node.children.indexOf(currentNode) > -1 ? 1 : 0
    let children_cnt = currentNode.children?.length || 0
    let docs = currentNode.children?.filter((x) => x.type === "blob").length || 0
    let replace_to = ""


    if (is_child && children_cnt === 1 && docs === 0) {
      counter += 1

    }
    else {

      //  prev_counter = counter + 0
      if (dest_node && is_child && counter > 0) {
        replace_to = dest_node.path
        console.log(`[optimizePPTree] moving content from  ${currentNode.path} to  ${replace_to}, docs: ${docs}, children:`, currentNode.children)
        dest_node.children = currentNode.children//[currentNode]

        // if(docs === 0) dest_node.children = currentNode.children //[currentNode]
        // else dest_node.children = [currentNode]

      }
      counter = 0
      // dest = ""
      dest_node = undefined
    }

    if (counter === 1) {
      //  dest = currentNode.parent_path
      dest_node = currentNode

    }

    //console.log( {is_child, children_cnt, docs, counter}, currentNode.parent_path, replace_to.length ? `=>${replace_to}`:`` ); // Process current node

    for (const child of currentNode.children || []) {
      nodes.push(child);
    }
    prev_node = currentNode
  }
  return cloned_tree
}


function buildTree(data: { path: string; type: string }[]): TreeNode {
  const root: TreeNode = { path: "", type: "tree", children: [] };
  for (const item of data) {
    const pathSegments = item.path.split("/");
    let node = root;
    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i];
      const currentPath = pathSegments.slice(0, i + 1).join("/");

      if (!node.children) {
        node.children = [];
      }

      if (i === pathSegments.length - 1) {
        node.children.push({ path: item.path, type: item.type });
      } else if (!node.children.find((child) => child.path === currentPath)) {
        // Use `children?.find` to handle potentially undefined children
        node.children.push({ path: currentPath, type: "tree", children: undefined });
      }

      node = node.children.find((child) => child.path === currentPath)!; // Use optional chaining to handle potentially undefined children
    }
  }
  return root;



}// Define a dictionary to store parent-child relationships.

const parseTree = (mdfiles: GhRepoTreeContentItem[]) => new Promise<Record<string, TreeNode>>(async (resolve, reject) => {

  // const { repo_tree_content } = _
  // const mdfiles = repo_tree_content.filter((item) => /(\w+)\.md$/i.test(item.path))

  mdfiles.sort((a, b) => a.path.includes("/") ? 1 : -1)
  const _tree = buildTree(mdfiles)
  const optimized = optimizeTreeNode(_tree)

  console.log("[parseTree] mdfiles", mdfiles)

  resolve({
    //  mdfiles: mdfiles,
    original: _tree,
    compact: optimized
  })

})




async function fetchFile(path: string): Promise<{ content: string }> {
  return new Promise((resolve, rej) => {
    console.log(`fetchFile promise started: ${path}`)

    return fetch(path, { cache: 'force-cache' })
      .then((response) => {
        if (response.ok) return response.text();
        else return Promise.reject("Didn't fetch text correctly");
      })
      .then((text) => {
        console.log(`fetchFile promise success: ${text.length}`)
        resolve({ content: text })
      })
      .catch((error) => console.error("fetchFile promise", error))
      .finally(() => console.log(`fetchFile promise finally:`))

  });
}

const fetchRepoMachine = setup({
  types: {} as {
    context: MachineContext,
    events: MachineEvent,
    input: MachineInput
  },
  actors: {
    //fetchMdFile: fromPromise(({ input }: { input: {username:string} }) => fetchFile(input.username)),
    fetchRepositoryDetails: fromPromise(({ input }: { input: { username: string, repository: string, access_token?: string } }) => getRepoDetails(input.username, input.repository, input.access_token ?? undefined)),
    fetchRepositoryTree: fromPromise(({ input }: { input: { details: GhRepoDetails, access_token?: string } }) => getRepoTreeRecursive(input.details, input.access_token ?? undefined)),
    parseRepositoryTree: fromPromise(({ input }: { input: { mdfiles: GhRepoTreeContentItem[] } }) => parseTree(input.mdfiles))
  },
  guards: {
    isEmpty: ({ context }) => {
      return context.details.default_branch === undefined;
    },
  },

}).createMachine({
  id: "repomachine",
  initial: 'idle',
  context: ({ input }) => ({
    username: "",
    access_token: input.access_token,
    repository: "",
    tree: undefined,
    path: undefined,
    details: {} as GhRepoDetails,
    branches: [] as GhRepoBranch[],
    content_tree: {} as GhRepoTree,
    files_tree: {} as TreeNode,
    files_tree_compact: {} as TreeNode,
    mdfiles: [],
    catalog_tree_display_settings: catalog_tree_display_settings_defaults,
    use_default_branch: false
  }),

  on: {
    'EVENTS.UI.SET_ACTIVE_DOC': {
      actions: [
        ({ context, event }) => console.log("repomachine.active_doc", `https://raw.githubusercontent.com/${context.username}/${context.repository}/${context.tree}/${event.path}`),
        sendTo(
          ({ system }) => system.get('mdeditor'),
          ({ context, event }) => ({
            type: 'EVENTS.FILE.OPEN',
            path: `https://raw.githubusercontent.com/${context.username}/${context.repository}/${context.tree}/${event.path}`,
          }))
      ],
    },
    'EVENTS.UI.CATALOG_TREE.UPDATE_DISPLAY_SETTINGS_VALUE' :{
      actions:[
        assign( ({ context, event })=>({ catalog_tree_display_settings:  { ...context.catalog_tree_display_settings, [event.key]: event.value }}))
      ]
    }
  },
  entry: [
    ({ event }) => console.log("repomachine.entry", event),
    // input: 
    assign(
      ({ context, event }) => {
        assertEvent(event, "xstate.init")
        const groups = parseRepoLink(event.input.link)

        const [origin, username, repository, tree, path] = groups
        console.log("mdeditor.load_repo.parseRepoLink.groups", { origin, username, repository, tree, path })
        return {
          username: username,
          repository: repository,
          tree: tree,
          path: path,
          use_default_branch: tree === undefined
        }
      }
    ),
  ],
  exit: [
    ({ event }) => console.log("repomachine.exit", event),
  ],
  states: {
    idle: {
      entry: [
        ({ event }) => console.log("repomachine.idle.entry", event),
      ],
      exit: [
        ({ event }) => console.log("repomachine.idle.exit", event),
      ],

      always: {
        target: "loading",
        guard: "isEmpty"
      }
    },
    loading: {
      entry: [
        ({ event }) => console.log("repomachine.loading.entry", event),
      ],
      exit: [
        ({ event }) => console.log("repomachine.loading.exit", event),
      ],

      initial: "get_repo_details",

      states: {
        get_repo_details: {
          entry: [
            ({ event }) => console.log("repomachine.loading.get_repo_details.entry", event),
          ],
          exit: [
            ({ event }) => console.log("repomachine.loading.get_repo_details.exit", event),
          ],
          invoke: {
            src: 'fetchRepositoryDetails',
            input: ({ context }) => ({ username: context.username, repository: context.repository, access_token: context.access_token }),
            //input: ({ input }:{ input:{username: string, repository:string, access_token:string} }) => ({ username: input.username, repository:input.repository, access_token:input.access_token }),
            onDone: {
              target: 'get_tree',
              actions: [
                ({ event }) => console.log("repomachine.loading.get_repo_details.fetchRepositoryDetails.onDone", event),
                assign({
                  details: ({ event }) => event.output,
                  tree: ({ context, event }) => context.use_default_branch ?  event.output.default_branch : context.tree 
              
                }),

              ]
            },
            onError: {
              target: '..failure',
              actions: [
                ({ event }) => console.log("repomachine.loading.get_repo_details.fetchRepositoryDetails.onError", event)
              ]
            }
          }
        },
        get_tree: {
          entry: [
            ({ event }) => console.log("repomachine.loading.get_tree.entry", event),
          ],
          exit: [
            ({ event }) => console.log("repomachine.loading.get_tree.exit", event),
          ],
          invoke: {
            src: "fetchRepositoryTree",
            input: ({ context }) => ({ details: context.details, access_token: context.access_token }),
            onDone: {
              target: 'parse_tree',
              actions: [
                ({ event }) => console.log("repomachine.loading.get_tree.fetchRepositoryTree.onDone", event),
                assign({
                  content_tree: ({ event }) => event.output,
                  mdfiles: ({ event }) => event.output.tree.filter((item: GhRepoTreeContentItem) => /(\w+)\.(md|markdown)$/i.test(item.path))
                })
              ]
            },
            onError: {
              target: '..failure',
              actions: [
                ({ event }) => console.log("repomachine.loading.get_tree.fetchRepositoryTree.onError", event)
              ]
            }
          }
        },
        parse_tree: {
          entry: [
            ({ event }) => console.log("repomachine.loading.parse_tree.entry", event),
          ],
          exit: [
            ({ event }) => console.log("repomachine.loading.parse_tree.exit", event),
          ],

          invoke: {
            src: "parseRepositoryTree",
            input: ({ context }) => ({ mdfiles: context.mdfiles }),
            onDone: {
              target: '#success',
              actions: [
                ({ event }) => console.log("repomachine.loading.parse_tree.parseRepositoryTree.onDone", event),
                assign(({ event }) => ({
                  files_tree: event.output.original,
                  files_tree_compact: event.output.compact
                }))
              ]
            },
            onError: {
              target: '..failure',
              actions: [
                ({ event }) => console.log("repomachine.loading.get_tree.fetchRepositoryTree.onError", event)
              ]
            }
          }
        }
      },



    },
    success: {
      id: "success",
      entry: [
        ({ event }) => console.log("repomachine.success.entry", event),
        // assign({ count:  0 })
      ],
      exit: [
        ({ event }) => console.log("repomachine.success.exit", event),
      ],
      after: {
        1000: "idle"
      }
    },
    failure: {
      entry: [
        ({ event }) => console.log("repomachine.failure.entry", event),
        // assign({ count: ({ context }) => context.count + 1 })
      ],
      exit: [
        ({ event }) => console.log("repomachine.failure.exit", event),
      ],
      after: {
        1000: {
          target: 'loading',
          //  guard: ({ context, event }) => context.count < 3
        },

      },
      // on: {
      //     RETRY: 'loading'
      // }
    }
  }
});

type RepoMachineActorRef = ActorRefFrom<typeof fetchRepoMachine>
export { fetchRepoMachine }
export type { RepoMachineActorRef }
