
import { useState, useEffect } from 'react'
import { Box } from '@mui/material'

import Divider from '@mui/material/Divider';
import { MdEditorMachineContext } from '../../../machines/main';

import { CatalogTreeRepoTitle } from './CatalogTreeRepoTitle'

import Button from '@mui/material/Button';

import { GitHub } from '@mui/icons-material';

import { type RepoMachineActorRef } from '../../../machines/getrepo';
import { GithubRepoDetailsView } from './GithubRepoDetailsView'
import { GithubRepositoryList } from './GithubViewRepositoryList'
import { LeftSidebarCatalogMain } from '../catalogtree/Main';

import { useTheme } from '@mui/material';
import { RepositoryForm } from './RepoForm'
const GithubTree = () => {

    const [showRepoForm, setShowRepoForm] = useState<boolean>(false)
    const theme = useTheme()
    const selected_repo = MdEditorMachineContext.useSelector((state) => state.context.selected_repo);
    //   const active_tab = MdEditorMachineContext.useSelector((state) => state.context.active_tab);
    const svc = MdEditorMachineContext.useActorRef();
    const gitreposvc = MdEditorMachineContext.useSelector((state) => selected_repo ? state.children[selected_repo] : undefined);


    const onRepoDeselect = () => svc.send({
        type: "EVENTS.REPO.SET_ACTIVE",
        id: null
    })

    const onFileOpen = (path: string) => svc.send({
        type: "EVENTS.FILE.OPEN",
        path: path
    })


    useEffect(()=>{

        if(showRepoForm === true) {
            onFileOpen('/docs/remark-gfm.md')
        }
    },[showRepoForm])

    return (
        <Box
            sx={{

                height: "100%"
            }}
        >
            {
                selected_repo ?
                    <Box
                        sx={{
                            height: `calc(100% - ${theme.spacing(8)})`,
                            overflowY: "auto"
                        }}
                    >
                        <CatalogTreeRepoTitle svc={gitreposvc as RepoMachineActorRef} onClose={onRepoDeselect} />
                        <Divider />
                        <LeftSidebarCatalogMain svc={gitreposvc as RepoMachineActorRef} onFileOpen={onFileOpen} />
                        {/* {
                        _state.matches('loading')?<>Loading</> :<> {_state.value}</>
                        } */}
                    </Box> :
                    showRepoForm ?
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%"
                            }}
                        >
                            <RepositoryForm />
                            <div style={{
                                flexGrow: 1,
                            }}></div>
                                 <Box
                                sx={{
                                    p: 1
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    startIcon={<GitHub />}
                                    fullWidth
                                    onClick={() => setShowRepoForm(!showRepoForm)}

                                >
                                    Close
                                </Button>
                            </Box>
                        </Box>
                        :

                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%"
                            }}
                        >
                            <GithubRepositoryList />
                            <div style={{
                                flexGrow: 1,
                            }}></div>
                            <Box
                                sx={{
                                    p: 1
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    startIcon={<GitHub />}
                                    fullWidth
                                    onClick={() => setShowRepoForm(!showRepoForm)}

                                >
                                    Add Repository
                                </Button>
                            </Box>
                        </Box>
            }
        </Box>
    )
}


export { GithubTree }